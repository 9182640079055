import { Warning2 } from "iconsax-react";
import { FaCheck } from "react-icons/fa6";
import { Toaster } from "sonner";
const SonnerToaster = () => {
  return <Toaster position="bottom-center" icons={{
    success: <FaCheck size={24} color={"#A1E3CB"} />,
    error: <Warning2 size={24} color={"white"} />
  }} toastOptions={{
    classNames: {
      error: "text-white bg-[#FF4747] max-w-fit border-none",
      success: "text-white bg-[#1C1C1CCC] max-w-fit border-none",
      content: "pl-1"
    }
  }} data-sentry-element="Toaster" data-sentry-component="SonnerToaster" data-sentry-source-file="sonner-toaster.tsx" />;
};
export default SonnerToaster;