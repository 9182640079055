"use client";

import { NextUIProvider } from "@nextui-org/react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ThemeProvider as NextThemesProvider } from "next-themes";
import { clientEnv } from "@/config/env/client-env";
import ReactQueryProvider from "@/providers/react-query-provider";
import SonnerToaster from "@/providers/sonner-toaster";
export function Providers({
  children
}: {
  children: React.ReactNode;
}) {
  return <ReactQueryProvider data-sentry-element="ReactQueryProvider" data-sentry-component="Providers" data-sentry-source-file="providers.tsx">
      <SonnerToaster data-sentry-element="SonnerToaster" data-sentry-source-file="providers.tsx" />
      <NextUIProvider data-sentry-element="NextUIProvider" data-sentry-source-file="providers.tsx">
        <NextThemesProvider attribute="class" defaultTheme="light" data-sentry-element="NextThemesProvider" data-sentry-source-file="providers.tsx">
          <GoogleOAuthProvider clientId={clientEnv.GOOGLE_CLIENT_ID} data-sentry-element="GoogleOAuthProvider" data-sentry-source-file="providers.tsx">
            {children}
          </GoogleOAuthProvider>
        </NextThemesProvider>
      </NextUIProvider>
    </ReactQueryProvider>;
}