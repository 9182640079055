"use client";

import { PropsWithChildren } from "react";
export default function AuthWrapper({
  children
}: PropsWithChildren) {
  return <div className="h-fit w-full bg-[#000]" data-sentry-component="AuthWrapper" data-sentry-source-file="auth-wrapper.tsx">
      <div className="relative grid h-fit w-full grid-cols-2 bg-white">
        <div className="col-span-2 flex w-full items-center justify-center p-5 md:col-span-1 md:p-0">
          {children}
        </div>

        <div className="col-span-1 hidden h-screen w-full bg-[url('/salon.png')] bg-cover bg-no-repeat md:block" />
      </div>
    </div>;
}