import Script from "next/script";
import React, { Fragment, useState } from "react";
import GoogleIcon from "@/assets/svgs/icons/GoogleIcon";
import Button from "./button";
type Props = {
  buttonLabel?: string;
  onClick: () => void;
  loading?: boolean;
  className?: string;
};
const GoogleLoginButton = ({
  buttonLabel = "Sign up with Google",
  onClick,
  loading = false
}: Props) => {
  const [isGoogleLoaded, setGoogleLoaded] = useState(false);
  return <Fragment>
      <Script src="https://accounts.google.com/gsi/client" strategy="afterInteractive" onLoad={() => setGoogleLoaded(true)} id="google-oauth-script" data-sentry-element="Script" data-sentry-source-file="google-login-button.tsx" />
      <Button className="w-full bg-black-default text-sm font-normal text-white" size="lg" isLoading={loading} isDisabled={loading || !isGoogleLoaded} onClick={onClick} data-sentry-element="Button" data-sentry-source-file="google-login-button.tsx">
        <GoogleIcon data-sentry-element="GoogleIcon" data-sentry-source-file="google-login-button.tsx" />
        <span>{buttonLabel}</span>
      </Button>
    </Fragment>;
};
export default GoogleLoginButton;