import { isPossiblePhoneNumber } from "react-phone-number-input";
import { z } from "zod";

export const UserLoginSchema = z.object({
  email: z.string().email(),
  password: z
    .string()
    .min(6, "Password Length must be greater than 6 characters"),
});

export const ForgetPasswordSchema = z.object({
  email: z.string().email(),
});
export const VerifyCodeSchema = z.object({
  email: z.string().email(),
  code: z
    .string()
    .min(4, "Code must be 4 characters")
    .max(4, "Code must be 4 characters"),
});

export const ResetPasswordSchema = z
  .object({
    new_password: z.string().min(8, "At least 8 characters"),
    confirm_password: z.string().min(8, "At least 8 characters"),
  })
  .refine((data) => data.new_password === data.confirm_password, {
    message: "Passwords do not match",
    path: ["confirm_password"],
  });

export const UserSignupSchema = z
  .object({
    first_name: z.string(),
    last_name: z.string(),
    email: z.string().email(),
    phone: z.string(),
    password: z
      .string()
      .min(6, "Password Length must be greater than 6 characters"),
  })
  .superRefine((data, context) => {
    const { phone } = data;

    const isValuePossiblePhoneNumber = isPossiblePhoneNumber(phone);
    if (!isValuePossiblePhoneNumber) {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Phone number is not valid",
        path: ["phone"],
      });
    }
  });
